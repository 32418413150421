code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --header-height: 3rem;
  --font-medium: 500;
}

:root {
  --first-color: #5361ff;
  --white-color: #fafaff;
  --dark-color: #2a3b47;
  --text-color: #697477;
}

:root {
  --body-font: "Montserrat", sans-serif;
  --big-font-size: 6.25rem;
  --h2-font-size: 1.25rem;
  --normal-font-size: 0.938rem;
  --small-font-size: 0.831rem;
}

@media screen and (min-width: 768px) {
  :root {
    --body-font: "Montserrat", sans-serif;
    --big-font-size: 10.5rem;
    --h2-font-size: 2rem;
    --normal-font-size: 1rem;
    --small-font-size: 0.875rem;
  }
}
:root {
  --mb-1: 0.5rem;
  --mb-2: 1rem;
  --mb-3: 1.5rem;
  --mb-4: 2rem;
  --mb-5: 3rem;
}

:root {
  --z-fixed: 100;
}
*,
::before,
::after {
  box-sizing: border-box;
}
body {
  margin: 0 0 0 0;
  font-family: var(--body-font);
  font-size: var(--normal-font-size);
  font-weight: var(--font-medium);
  color: var(--text-color);
  line-height: 1.6;
}
@media screen and (min-width: 768px) {
  body {
    margin: 0 0 0 0;
  }
}
